import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacidad = ({ pageContext }) => (
	<Layout {...pageContext}>
		<SEO 
			lang={pageContext.lang}
			path={pageContext.pageUrl}
			title={pageContext.title}
			noindex={true}
		/>
		<div id="header" className="for-properties">
			<div className="section-content">
				<div className="big-text">
					<h1 className="t-left f-large normal">{pageContext.title}</h1>
				</div>
			</div>
		</div>
		<div id="description">
			<div className="section-content">
				<div className="row">
					<div className="col-md-8">
						<div className="brief no-margin t-left link-underline">
							<p>Pat Swiss Invest SL te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios  que puedan ser recabados durante la navegación a través del sitio Web <Link to="/">patricia.properties</Link>.</p>
							<p>En este sentido, Pat Swiss Invest SL garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).</p>
							<p>El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el <Link to="/es/aviso-legal/">Aviso Legal</Link>.</p>
							<h2>Identidad del responsable</h2>
							<ul>		
								<li><b>Titular:</b>	Pat Swiss Invest SL</li>
								<li><b>CIF:</b> B54855226</li>
								<li>Inscrita en el registro mercantil de Alicante, tomo 3.854, folio 67, hoja A-144750, inscripción 1ª.</li>		
								<li><b>Domicilio:</b> Calle Rosario 10, 44164 Visiedo - España.</li>
								<li><b>Correo electrónico:</b> <a href="mailto:legal@patricia.properties">legal@patricia.properties</a></li>
								<li><b>Sitio Web:</b> <Link to="/">https://patricia.properties</Link></li>
							</ul>
							<h2>Principios aplicados en el tratamiento de datos</h2>
							<p>En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos (RGPD):</p>
							<ul>
								<li>
									<b>Principio de licitud, lealtad y transparencia:</b> El Titular siempre requerirá el consentimiento para el tratamiento de los datos personales que puede ser para uno o varios fines específicos sobre los que el Titular informará al Usuario previamente con absoluta transparencia.
								</li>
								<li>
									<b>Principio de minimización de datos:</b> El Titular solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita. 
								</li>	
								<li>
									<b>Principio de limitación del plazo de conservación:</b> El Titular mantendrá los datos personales recabados durante el tiempo estrictamente necesario para el fin o los fines del tratamiento. El Titular informará al Usuario del plazo de conservación correspondiente <a href="#finalidad-del-tratamiento-de-datos-personales">según la finalidad</a>. 
									<br />En el caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.
								</li>
								<li>
									<b>Principio de integridad y confidencialidad:</b> Los datos personales recabados serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada.			
									<br />El Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.
								</li>
							</ul>
							<h2>Obtención de datos personales</h2>
							<p>Para navegar por <Link to="/">patricia.properties</Link> no es necesario que facilites ningún dato personal.</p>
							<p>Los casos en los que sí proporcionas tus datos personales son los siguientes:</p>
							<ul>		
								<li>Al contactar a través de los formularios de contacto o enviar un correo electrónico.</li>
								<li>Al inscribirte en un formulario de suscripción o un boletín que el Titular gestiona con <a href="#destinatarios">MailChimp</a>.</li>
							</ul>
							<h2>Derechos</h2>
							<p>El Titular te informa que sobre tus datos personales tienes derecho a:</p>
							<ul>
								<li>Solicitar el acceso a los datos almacenados.</li>
								<li>Solicitar una rectificación o la cancelación.</li>
								<li>Solicitar la limitación de su tratamiento.</li>
								<li>Oponerte al tratamiento.</li>
							</ul>
							<p>No puedes ejercitar el derecho a la portabilidad de los datos.</p>
							<p>El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento, puede dirigirse a Pat Swiss Invest SL y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, oponerse al tratamiento, limitar su uso o solicitar la cancelación de esos datos en los ficheros del Titular.</p>
							<p>Para ejercitar tus derechos de acceso, rectificación, cancelación y oposición tienes que enviar un correo electrónico a <a href="mailto:legal@patricia.properties">legal@patricia.properties</a> junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.</p>
							<p>Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.</p>
							<h2 id="finalidad-del-tratamiento-de-datos-personales">Finalidad del tratamiento de datos personales</h2>
							<p>Cuando te conectas al sitio Web para mandar un correo al Titular, te suscribes a su boletín estás facilitando información de carácter personal de la que el responsable es Pat Swiss Invest SL. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por Pat Swiss Invest SL sólo como se describe en el <Link to="/es/aviso-legal/">Aviso Legal</Link> y en la presente <Link to="/es/politica-de-privacidad/">Política de Privacidad</Link>.</p>
							<p>Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:</p>
							<ul>		
								<li>
									<b>Formularios de contacto:</b> El Titular solicita datos personales entre los que pueden estar: nombre y apellidos, dirección de correo electrónico, número de teléfono y dirección de sitio Web con la finalidad de responder las consultas de los Usuarios.<br />
									Por ejemplo, Pat Swiss Invest SL utiliza esos datos para dar respuesta a mensajes, dudas, quejas, comentarios o inquietudes que pueden tener los Usuarios relativas a la información incluida en el sitio Web, el tratamiento de los datos personales, cuestiones referentes a los textos legales incluidos en el sitio Web, así como cualquier otra consulta que el Usuario pueda tener y que no esté sujeta a las condiciones del sitio Web.
								</li>
								<li>
									<b>Formularios de suscripción a contenidos:</b> El Titular solicita los siguientes datos personales: nombre y apellidos, dirección de correo electrónico, número de teléfono y dirección de un sitio web para gestionar la lista de suscripciones, enviar boletines, promociones y ofertas especiales.<br />
									Los datos personales facilitados al Titular estarán ubicados en los servidores de The Rocket Science Group LLC d/b/a, con domicilio en EEUU. (Mailchimp).	
								</li>
							</ul>
							<p>Existen otras finalidades por las que el Titular trata datos personales:</p>
							<ul>
								<li>Para garantizar el cumplimiento de las condiciones recogidas en el <Link to="/es/aviso-legal/">Aviso Legal</Link> y en la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio Web a garantizar la confidencialidad de los datos personales que recoge.</li>
								<li>Para apoyar y mejorar los servicios que ofrece este sitio Web.</li>
								<li>Para analizar la navegación de los usuarios. El Titular recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en el ordenador del Usuario cuando navega por el sitio Web cuyas características y finalidad están detalladas en la <Link to="/es/politica-de-cookies/">Política de Cookies</Link>.</li>
								<li>
									<p>Para gestionar las redes sociales. Pat Swiss Invest SL tiene presencia en redes sociales. Si te haces seguidor en las redes sociales del Titular el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.
										<br />
										Puedes consultar las políticas de privacidad de las principales redes sociales en estos enlaces:
									</p>		
									<ul>
										<li><a href="https://www.facebook.com/help/cookies" rel="nofollow noreferrer" target="_blank">Facebook</a></li>
										<li><a href="https://www.linkedin.com/legal/cookie-policy" rel="nofollow noreferrer" target="_blank">Linkedin</a></li>
										<li><a href="https://www.instagram.com/legal/privacy/" rel="nofollow noreferrer" target="_blank">Instagram</a></li>
									</ul>
									<p>El Titular tratará tus datos personales con la finalidad de administrar correctamente su presencia en la red social, informarte de sus actividades, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.</p>
									<p>En ningún caso el Titular utilizará los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.</p>
								</li>
							</ul>
							<h2>Seguridad de los datos personales</h2>
							<p>Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.</p>
							<p>Tus datos podrán ser incorporados a un fichero de lista de correo, del cual Pat Swiss Invest SL es responsable de su gestión y tratamiento. La seguridad de tus datos está garantizada, ya que Pat Swiss Invest SL toma todas las medidas de seguridad necesarias y te garantiza que los datos personales sólo se usarán para las finalidades dadas.</p>
							<p>Pat Swiss Invest SL informa al Usuario de que sus datos personales no serán cedidos a terceras organizaciones, con la salvedad de que dicha cesión de datos esté amparada en una obligación legal o cuando la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento. En este último caso, solo se llevará a cabo la cesión de datos al tercero cuando Pat Swiss Invest SL disponga del consentimiento expreso del Usuario.</p>
							<p>Sin embargo, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerirá consentimiento al Usuario informando sobre la identidad del colaborador y la finalidad de la colaboración. Siempre se realizará con los más estrictos estándares de seguridad.</p>
							<h2>Contenido de otros sitios web</h2>
							<p>Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si hubieras visitado la otra web.</p>
							<p>Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.</p>
							<h2>Política de Cookies</h2>
							<p>Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una información que se almacena en tu navegador web.</p>
							<p>En la página <Link to="/es/politica-de-cookies/">Política de Cookies</Link> puedes consultar toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.</p>
							<h2>Legitimación para el tratamiento de datos</h2>
							<p>La base legal para el tratamiento de tus datos es: el consentimiento.</p>
							<p>Para contactar con el Titular, suscribirte a un boletín o realizar comentarios en este sitio Web tienes que aceptar la presente Política de Privacidad.</p>
							<h2>Categorías de datos personales</h2>
							<p>Las categorías de datos personales que trata el Titular son:</p>
							<ul>		
								<li>Datos identificativos.</li>
							</ul>
							<h2>Conservación de datos personales</h2>
							<p>Los datos personales que proporciones al Titular se conservarán hasta que solicites su supresión.</p>
							<div id="destinatarios">
								<h2>Destinatarios de datos personales</h2>
								<ul>
									<li>
										<b>Mailchimp</b> The Rocket Science Group LLC d/b/a , con domicilio en EEUU. 
										<br />Más información en: <a href="https://mailchimp.com/legal/privacy/" rel="nofollow noreferrer" target="_blank">https://mailchimp.com</a>
										<br />The Rocket Science Group LLC d/b/a trata los datos con la finalidad de prestar sus servicios de email marketing al Titular.
									</li>
									<li>
										<b>Google Analytics</b> es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google"). 			
										<br />Google Analytics utiliza "cookies", que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los Usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo la dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.
										<br />Más información en: <a href="https://policies.google.com/privacy?hl=es-ES" rel="nofollow noreferrer" target="_blank">https://analytics.google.com</a>
									</li>
									<li>
										<b>Google AdSense</b> es un conjunto de servicios publicitarios proporcionado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google"). 
										<br />AdSense utiliza las cookies para mejorar la publicidad, segmentarla según el contenido que sea relevante para los usuarios y mejorar los informes de rendimiento de las campañas.
										<br />Más información en: <a href="https://policies.google.com/privacy?hl=es-ES" rel="nofollow noreferrer" target="_blank">https://www.google.com/adsense</a>
									</li>
								</ul>
							</div>		
							<p>En la página de <a target="_blank" href="https://policies.google.com/privacy?hl=es" rel="nofollow noreferrer">Política de privacidad de Google</a> se explica cómo Google gestiona la privacidad en lo que respecta al uso de las cookies y otra información.</p>
							<p>También puedes ver una lista de los <a target="_blank" href="https://policies.google.com/technologies/types?hl=es" rel="nofollow noreferrer">tipos de cookies</a> que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de las <a target="_blank" rel="nofollow noreferrer" href="https://policies.google.com/technologies/ads?hl=es">cookies publicitarias</a>.</p>	
							<h2>Navegación Web</h2>
							<p>Al navegar por <Link to="/">patricia.properties</Link> se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.</p>
							<p>El sitio Web utiliza los siguientes servicios de análisis de terceros:</p>
							<ul>
								<li>Google Analytics.</li>
								<li>Google AdSense.</li>
							</ul>
							<p>El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.</p>
							<p>El Titular no se hace responsable del tratamiento de los datos personales que realicen las páginas web a las que puedas acceder a través de los distintos enlaces que contiene el sitio Web.</p>
							<h2>Exactitud y veracidad de los datos personales</h2>
							<p>Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
							<p>Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos remitidos al sitio Web exonerando a Pat Swiss Invest SL de cualquier responsabilidad al respecto.</p>
							<h2>Aceptación y consentimiento</h2>
							<p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte de Pat Swiss Invest SL en la forma y para las finalidades indicadas en esta Política de Privacidad.</p>
							<h2>Revocabilidad</h2>
							<p>Para ejercitar tus derechos de acceso, rectificación, cancelación y oposición tienes que envia un correo electrónico a <a href="mailto:legal@patricia.properties">legal@patricia.properties</a> junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.</p>
							<p>El ejercicio de estos derechos no incluye ningún dato que Pat Swiss Invest SL esté obligado a conservar con fines administrativos, legales o de seguridad.</p>
							<h2>Cambios en la Política de Privacidad</h2>
							<p>El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.</p>
							<p>Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</Layout>
);

export default Privacidad;
